import {
  ErrorSimple,
  Loader,
  PageHeaderSg,
  PagingSg,
  PhaseBanner,
  SelectComponent,
  PrimaryButton,
  SearchAsYouTypeComponent,
} from '@scts-ui/component-library';
import { FileBlob } from '@scts/models';
import { useEffect, useState } from 'react';
import {
  getContainer,
  getBlobs,
  useConfigLoader,
} from 'dataaccessts';
import { useTitleService } from '@scts/utilities';
import DecisionsTable, { renderSubComponent } from './decisions-table';
import { DecisonsParentColumns } from './decisions-columns';
import { SelectOptionProps } from '@scts-ui/component-library';
import { creatingFilterArraysForDecisons } from '../../utils/filter';
import { filterAndSearchDecisions } from '../../utils/combinedFiltersAndSearch';
import {renderWithPaging} from '../../utils/paging';


export const Decisions = () => {
  const config = useConfigLoader();
  useTitleService('Tribunal Decisions: Local Taxation Chamber');
  const [current, setCurrent] = useState<number>(1);
  const [blobItems, setBlobItems] = useState<FileBlob[]>([]);
  const [currentBlobItems, setCurrentBlobItems] = useState<FileBlob[]>([]);
  const [filteredBlobItems, setFilteredBlobItems] = useState<FileBlob[]>([]);
  const [noBlobs, setNoBlobs] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [noResults, setNoResults] = useState<boolean>(false);
  const itemsInAPage = 8;
  //filter arrays
  const [appealTypes, setAppealTypes] = useState<SelectOptionProps[]>([]);
  const [decisions, setDecisions] = useState<SelectOptionProps[]>([]);
  const [respondents, setRespondents] = useState<SelectOptionProps[]>([]);
  //active filter item
  const [appealTypeFilter, setAppealTypeFilter] = useState<string>('All');
  const [decisionFilter, setDecisionFilter] = useState<string>('All');
  const [respondentFilter, setRespondentFilter] = useState<string>('All');

  const [ isCancelClicked, setIsCancelClicked ] = useState<boolean>(false);

  const [ searchTermLength, setSearchTermLength ] = useState<number>(0);

  useEffect(() => {
    if (config.data) {
      const getStorageblobs = async () => {
        const cont = getContainer(
          config.data.storageAccount,
          config.data.documentsFolder,
          config.data.frontDoorUrl
        );
        const blobs = await getBlobs(cont);
        if (blobs.length === 0) setNoBlobs(true);
        setBlobItems(blobs);
      };

      getStorageblobs().catch(() => {
        setNetworkError(true);
      });
    }
  }, [config.data]);

  useEffect(() => {
    if (blobItems.length > 0) {
      blobItems.sort((objA, objB) => {
        return (
          new Date(objB.DecisionDate).getTime() -
          new Date(objA.DecisionDate).getTime()
        );
      });
      childPageClicked(1);
      setNoBlobs(false);
    }
  }, [blobItems]);

  useEffect(() => {
    if (blobItems.length > 0) {
      if (noResults) setNoResults(false);
      const filteredAndSearchedDecisions: FileBlob[] = filterAndSearchDecisions(blobItems, decisionFilter, appealTypeFilter, respondentFilter, searchTerm);
      setFilteredBlobItems(filteredAndSearchedDecisions);
      if (filteredAndSearchedDecisions.length === 0) setNoResults(true);
    }
  }, [blobItems, decisionFilter, appealTypeFilter, respondentFilter, searchTerm]);

  useEffect(() => {
    if (blobItems.length > 0) {
      const filterArrays = creatingFilterArraysForDecisons(filteredBlobItems);
      setAppealTypes(filterArrays[0]);
      setDecisions(filterArrays[1]);
      setRespondents(filterArrays[2]);
    }
  }, [blobItems, filteredBlobItems]);

  useEffect(() => {
    //as a result of using the search while on, for example, the last page, the search result may fit in less pages and the below makes sure that the last page will be refreshed and correctly numbered
    if((current > Math.ceil(filteredBlobItems.length / itemsInAPage) && current !== 1)) {
      setCurrent(Math.ceil(filteredBlobItems.length / itemsInAPage));
    } else {
      setCurrentBlobItems(renderWithPaging(filteredBlobItems, current, itemsInAPage));
    }
  }, [filteredBlobItems, current, itemsInAPage]);


  const childPageClicked = (page: number) => {
    setCurrent(page);
    setIsCancelClicked(!isCancelClicked);
  };
  
  const onSearchBoxChange = (searchInput: string) => {
    const condition = searchInput.length === 2 && searchInput.length < searchTermLength;
    setSearchTerm(searchInput);
    if ( searchInput.length > 2 || condition) {
      setIsCancelClicked(!isCancelClicked);
    }
    setSearchTermLength(searchInput.length);
    console.log(searchTermLength);
  };

  const onAppealTypeFilterChange = (option: HTMLOptionElement) => {
    setAppealTypeFilter(option.value);
  };

  const onDecisionFilterChange = (option: HTMLOptionElement) => {
    setDecisionFilter(option.value);
  };

  const onRespondentFilterChange = (option: HTMLOptionElement) => {
    setRespondentFilter(option.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setAppealTypeFilter('All');
    setRespondentFilter('All');
    setDecisionFilter('All');
    setCurrent(1);
    setIsCancelClicked(!isCancelClicked);
  }

  return (
    <>
      <PageHeaderSg headerText={'Published Decisions'} />
      <p>When searching for decision information, you can use the drop down menus or type into the free text search box. Additional fields you can search for include: appeal type, property address and representative.</p>  
      <div className="d-flex flex-sm-column flex-md-row filter-row">
        <div className="filter">
          <SelectComponent 
            selectId={'appeal-type-filter'} 
            selectedValue={appealTypeFilter} 
            selectedDisabled={false} 
            selectLabelText={'Appeal Type'} 
            selectName={'Appeal Type Filter'}
            selectOptions={appealTypes}
            onChange={onAppealTypeFilterChange} 
          />
        </div>
        <div className="filter">
          <SelectComponent 
            selectId={'decision-filter'} 
            selectedValue={decisionFilter} 
            selectedDisabled={false} 
            selectLabelText={'Decision'} 
            selectName={'Decision Filter'}
            selectOptions={decisions} 
            onChange={onDecisionFilterChange} 
          />
        </div>
        <div className="filter">
          <SelectComponent 
            selectId={'respondent-filter'} 
            selectedValue={respondentFilter} 
            selectedDisabled={false} 
            selectLabelText={'Respondent'} 
            selectName={'Respondent Filter'}
            selectOptions={respondents} 
            onChange={onRespondentFilterChange} 
          />
        </div>
      </div>
      <div className="d-flex flex-sm-column flex-md-row search-row">
    <div className="search">
      <SearchAsYouTypeComponent 
          inputId={"search"} 
          inputValue={searchTerm}
          labelText={"Search all fields (at least 3 characters are necessary)"} 
          inputType={"text"} 
          placeholder={"Search as you type..."} 
          searchLabel={"search"} 
          onSearch={(e: string) => {
            onSearchBoxChange(e);
          }} />
          </div>
        <div className=" clear-button">
          <PrimaryButton
            buttonText={'Clear All'}
            onClick={clearSearch} 
          />
        </div>
      </div>
      <div className="d-flex flex-sm-column flex-md-row search-row">
        <p className='nr-of-results'>Number of decisions: {filteredBlobItems.length}</p>
      </div>

      {!networkError &&
        currentBlobItems &&
        !noBlobs &&
        currentBlobItems.length === 0 && !noResults && (
          <Loader loadingText={'getting decisions'} padding={''} />
        )}

      {!networkError && currentBlobItems && currentBlobItems.length > 0 && !noResults && !isCancelClicked && (
        <div className="d-flex flex-column">
          <PagingSg
            numberOfPages={Math.ceil(filteredBlobItems.length / itemsInAPage)}
            numberOfItems={filteredBlobItems.length}
            currentPage={current}
            numberOfPageLinks={5}
            pageClicked={childPageClicked}
          />
          <DecisionsTable 
            data={currentBlobItems} 
            columns={DecisonsParentColumns} 
            renderSubComponent={renderSubComponent} 
            getRowCanExpand={() => true} 
          />
        </div>
      )}  

      {!networkError && currentBlobItems && currentBlobItems.length > 0 && !noResults && isCancelClicked && (
        <div className="d-flex flex-column">
          <PagingSg
            numberOfPages={Math.ceil(filteredBlobItems.length / itemsInAPage)}
            numberOfItems={filteredBlobItems.length}
            currentPage={current}
            numberOfPageLinks={5}
            pageClicked={childPageClicked}
          />
          <DecisionsTable 
            data={currentBlobItems} 
            columns={DecisonsParentColumns} 
            renderSubComponent={renderSubComponent} 
            getRowCanExpand={() => true} 
          />
        </div>
      )}

      {!networkError && noBlobs && (
        <PhaseBanner
          tag={'No Decisions Yet'}
          link={'/contact-us'}
          text1={
            'This is a new service so there may be a delay in decisions being published.'
          }
          text2={'if you believe this to be incorrect.'}
          linkText={'Contact us'}
        />
      )}

      {!networkError && !noBlobs && currentBlobItems.length === 0 && noResults && (
        <PhaseBanner
          tag={'No Decisions Found'}
          link={'/decisions'}
          text1={
            'No decisions found based on the search term.'
          }
          text2={'Please change the search term, or select Reset filters link in order to remove all filters and the search term'}
          linkText={'Reset filters.'}
        />
      )}

      {networkError && (
        <ErrorSimple
          message={
            'We have been unable to retrieve decisions at the moment. Please try later or contact us via the contact page'
          }
          padding={'0.5rem'}
          margin={''}
          icon={'bi bi-exclamation-square-fill'}
          iconFontSize={'4rem'}
          textFontSize={''}
        />
      )}
    </>
  );
};
export default Decisions;
