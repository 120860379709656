import { AppDataLocalTax } from '@scts/app-data';
import { ContactSg, Loader, PageHeaderSg } from '@scts-ui/component-library';
import { useConfigLoader } from 'dataaccessts';
import { useTitleService, replaceDevLinkWithConfig } from '@scts/utilities';
import { useEffect, useState } from 'react';

export const ContactUs = () => {
  const [updated, setUpdated] = useState(false);
  const config = useConfigLoader();
  useTitleService('Contact Us: Local Taxation Chamber');

  useEffect(() => {
    if (config.data?.storageAccount) {
      //dev/test different from pp/pa
      AppDataLocalTax.contact.publicHolidayLink = replaceDevLinkWithConfig(
        config.data.frontDoorUrl,
        config.data.storageAccount,
        AppDataLocalTax.contact.publicHolidayLink
      );
      setUpdated(true);
    }
  }, [config.data]);

  return (
    <div className="contact-wrapper">
      <PageHeaderSg headerText={'Contact'} />

      {!updated && <Loader loadingText={''} padding={''} />}
      {updated && (
        <ContactSg
          address={AppDataLocalTax.contact.address}
          orginisationName={AppDataLocalTax.contact.orginisationName}
          email={AppDataLocalTax.contact.email}
          phone={AppDataLocalTax.contact.phone}
          phoneText={AppDataLocalTax.contact.phoneText}
          publicHolidayLink={AppDataLocalTax.contact.publicHolidayLink}
          publicHolidayText={AppDataLocalTax.contact.publicHolidayText}
          complaints={AppDataLocalTax.contact.complaints}
        />
      )}
    </div>
  );
};

export default ContactUs;
