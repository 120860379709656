import { AppDataLocalTax } from '@scts/app-data';
import { Accessibility, AdditionalItem } from '@scts-ui/component-library';
import { useConfigLoader } from 'dataaccessts';
import {
  replaceDevLinkWithConfig,
  useTitleService,
} from '@scts/utilities';
import { useEffect, useState } from 'react';

const AccessibilityWrapper = () => {
  const config = useConfigLoader();
  useTitleService('Accessibility Statement: Local Taxation Chamber');

  const [additionalItems, setAdditionalItems] = useState<AdditionalItem[]>();

  useEffect(() => {
    if (config.data?.storageAccount) {
      const newLinks = AppDataLocalTax.accessibility.additionalItems;
      newLinks.map((item) => {
        if (item.url.toLocaleLowerCase().indexOf('ltcdv') !== -1) {
          item.url = replaceDevLinkWithConfig(
            config.data.frontDoorUrl,
            config.data.storageAccount,
            item.url
          );
        }
      });

      setAdditionalItems(newLinks);
    }
  }, [config.data]);

  return (
    <Accessibility
      contactEmail={AppDataLocalTax.accessibility.contactEmail}
      complianceIssues={AppDataLocalTax.accessibility.complianceIssues}
      datePrepared={'01 April 2023'}
      additionalItems={additionalItems}
    />
  );
};

export default AccessibilityWrapper;
