import { AppDataLocalTax } from '@scts/app-data';
import {
  HeaderSg,
  NotFound,
  FooterMulti,
  Foi,
} from '@scts-ui/component-library';
import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Faqs from './features/faqs/faqs';
import About from './features/about/about';
import Apply from './features/apply/apply';
import ContactUs from './features/contact-us/contact-us';
import Decisions from './features/decisions/decisions';
import ExistingAppeals from './features/existing-appeals/existing-appeals';
import Home from './features/home/home';
import Reviews from './features/reviews/reviews';
import Legislation from './features/legislation/legislation';
import TermsConditions from './components/terms-conditions';
import { useConfigLoader } from 'dataaccessts';
import { FooterLinks } from '@scts/models';
import AccessibilityWrapper from './components/accessibility-wrapper';
import { replaceDevLinkWithConfig } from '@scts/utilities';
import Hearings from './features/hearings/hearings';

export const App = () => {
  const config = useConfigLoader();
  const location = useLocation();
  const [footerLinksReplaced, setFooterLinksReplaced] = useState<FooterLinks>();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState('fadeIn');

  useEffect(() => {
    if (location !== displayLocation) {
      setTransistionStage('fadeOut');
    }
  }, [location, displayLocation]);

  useEffect(() => {
    if (config.data?.storageAccount) {
      //dev/test different from pp/pa
      const newLinks: FooterLinks = {
        links: AppDataLocalTax.taxLinks1.links,
        title: AppDataLocalTax.taxLinks1.title,
      };

      newLinks.links.map((item) => {
        if (item.href.toLocaleLowerCase().indexOf('ltcdv') !== -1) {
          item.href = replaceDevLinkWithConfig(
            config.data.frontDoorUrl,
            config.data.storageAccount,
            item.href
          );
        }
      });
      setFooterLinksReplaced(newLinks);
    }
  }, [config.data]);

  return (
    <>
      <div className="ds_skip-links">
        <ul className="ds_skip-links__list">
          <li className="ds_skip-links__item">
            <a
              className="ds_skip-links__link"
              href={location.pathname + '#main-content'}
              data-navigation="skip-link-1"
            >
              Skip to main content
            </a>
          </li>
        </ul>
      </div>
      <div>
        <HeaderSg
          headerText={AppDataLocalTax.header.headerText}
          headerMenuItems={AppDataLocalTax.header.menuItems}
          headerSpecialMenuItems={[]}
          logoImageAddress={'./assets/images/local-tax-lg.jpg'}
          logoSmallImageAddress={'./assets/images/scts-logo-sm.png'}
          imageAltText="Local Taxation Chamber First-Tier Tribunal for Scotland"
        />
      </div>

      <main
        id="main-content"
        className="flex-shrink-0 col-12 col-lg-10 offset-lg-1 col-xxl-8 offset-xxl-2 p-2 "
      >
        <div
          className={`${transitionStage}`}
          onAnimationEnd={() => {
            if (transitionStage === 'fadeOut') {
              setTransistionStage('fadeIn');
              setDisplayLocation(location);
            }
          }}
        >
          <Routes location={displayLocation}>
            <Route
              path="*"
              element={
                <NotFound
                  links={AppDataLocalTax.notFound.links}
                  padding={'1rem'}
                  margin={'0'}
                  paddingTop={'0'}
                />
              }
            />

            <Route path={'/'} element={<Home />} />
            <Route path={'/decisions'} element={<Decisions />} />
            <Route path={'/hearings'} element={<Hearings />} />
            <Route path={'/about'} element={<About />} />
            <Route path={'/apply'} element={<Apply />} />
            <Route
              path={'/foi'}
              element={
                <Foi
                  linkUrl={AppDataLocalTax.foi.linkUrl}
                  linkTitle={AppDataLocalTax.foi.linkTitle}
                />
              }
            />
            <Route path={'/faqs'} element={<Faqs />} />
            <Route path={'/reviews'} element={<Reviews />} />
            <Route path={'/terms'} element={<TermsConditions />} />
            <Route path={'/legislation'} element={<Legislation />} />
            <Route path={'/accessibility'} element={<AccessibilityWrapper />} />
            <Route path={'/existing-appeals'} element={<ExistingAppeals />} />
            <Route path={'/contact-us'} element={<ContactUs />} />
            <Route path={'/hearings'} element={<Hearings />} />
          </Routes>
        </div>
      </main>

      <div className="footer mt-auto">
        <FooterMulti
          col1Links1={AppDataLocalTax.taxLinks1}
          col1Links2={AppDataLocalTax.taxLinks2}
          col2Links1={AppDataLocalTax.usefulLinksFooter1}
          col2Links2={AppDataLocalTax.usefulLinksFooter2}
          backgroundColour={AppDataLocalTax.footer.backgroundColour}
          colour={AppDataLocalTax.footer.colour}
          padding={AppDataLocalTax.footer.padding}
          paddingBottom={AppDataLocalTax.footer.paddingBottom}
          copyrightMessage={AppDataLocalTax.footer.copyrightMessage}
        />
      </div>
    </>
  );
};

export default App;
