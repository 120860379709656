import {
  ErrorSimple,
  PageHeaderSg,
  PhaseBanner,
  SelectComponent,
  PrimaryButton,
  Loader,
  PagingSg,
  SearchAsYouTypeComponent
} from '@scts-ui/component-library';
import ExcelExport from '../../components/excelExport'
import { useTitleService } from '@scts/utilities';
import {
  getContainer,
  getJsonBlob,
  useConfigLoader,
} from 'dataaccessts';
import { useEffect, useState } from 'react';
import { LtcHearing } from '@scts/models';
import { AppDataLocalTax } from '@scts/app-data';
import {
  replaceDevLinkWithConfig,
} from '@scts/utilities';
import { HearingsParentColumns } from './hearings-columns';
import HearingsTable, { renderSubComponent } from './hearings-table';
import { SelectOptionProps } from '@scts-ui/component-library';
import { filterAndSearchHearings } from '../../utils/combinedFiltersAndSearch';
import { creatingFilterArraysForHearings } from '../../utils/filter';
import { renderWithPaging } from '../../utils/paging';

const Hearings = () => {
  const config = useConfigLoader();
  useTitleService('Forthcoming Hearings: Local Taxation Chamber');
  const itemsInAPage = 8;
  const [current, setCurrent] = useState<number>(1);
  const [networkError, setNetworkError] = useState(false);
  const [noBlobs, setNoBlobs] = useState(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [hearings, setHearings] = useState<LtcHearing[]>([]);
  const [currentHearings, setCurrentHearings] = useState<LtcHearing[]>([]);
  const [filteredHearings, setFilteredHearings] = useState<LtcHearing[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  //filter arrays
  const [dates, setDates] = useState<SelectOptionProps[]>([]);
  const [appealTypes, setAppealTypes] = useState<SelectOptionProps[]>([]);
  const [respondents, setRespondents] = useState<SelectOptionProps[]>([]);
  //active filter item
  const [appealTypeFilter, setAppealTypeFilter] = useState<string>('All');
  const [dateFilter, setDateFilter] = useState<string>('All');
  const [respondentFilter, setRespondentFilter] = useState<string>('All');

  const [ isCancelClicked, setIsCancelClicked ] = useState<boolean>(false);

  const [ searchTermLength, setSearchTermLength ] = useState<number>(0);

  useEffect(() => {
    if (config.data) {
      const getHearingJson = async () => {
        const cont = getContainer(
          config.data.storageAccount,
          config.data.dataFilesFolder,
          config.data.frontDoorUrl
        );
        const jsonBlob = await getJsonBlob(cont, config.data.hearingsFileName);
        if (!jsonBlob) setNoBlobs(true);
        setHearings(JSON.parse(jsonBlob));
      };

      getHearingJson().catch(() => {
        setNetworkError(true);
      });      
    }
  }, [config.data]);

  useEffect(() => {
    if(hearings.length > 0) {
      childPageClicked(1);
      setNoBlobs(false);
      setCurrentHearings(hearings);
    }
  }, [hearings]);

  useEffect(() => {
    if (hearings.length > 0) {
      if (noResults) setNoResults(false);
      const filteredAndSearchedHearings: LtcHearing[] = filterAndSearchHearings(hearings, dateFilter, appealTypeFilter, respondentFilter, searchTerm);
      setFilteredHearings(filteredAndSearchedHearings);
      if (filteredAndSearchedHearings.length === 0) setNoResults(true);
    }
  }, [hearings, dateFilter, appealTypeFilter, respondentFilter, searchTerm]);

  useEffect(() => {
    if (hearings.length > 0) {
      const filterArrays = creatingFilterArraysForHearings(filteredHearings);
      setDates(filterArrays[0]);
      setAppealTypes(filterArrays[1]);
      setRespondents(filterArrays[2]);
    }
  }, [hearings, filteredHearings]);
  useEffect(() => {
    //as a result of using the search while on, for example, the last page, the search result may fit in less pages and the below makes sure that the last page will be refreshed and correctly numbered
    if((current > Math.ceil(filteredHearings.length / itemsInAPage) && current !== 1)) {
      setCurrent(Math.ceil(filteredHearings.length / itemsInAPage));
    } else {
      setCurrentHearings(renderWithPaging(filteredHearings, current, itemsInAPage));
    }
  }, [filteredHearings, current, itemsInAPage]);


  const childPageClicked = (page: number) => {
    setCurrent(page);
    setIsCancelClicked(!isCancelClicked);
  };  
  
  const onSearchBoxChange = (searchInput: string) => {
    const condition = searchInput.length === 2 && searchInput.length < searchTermLength;
    setSearchTerm(searchInput);
    if ( searchInput.length > 2 || condition) {
      setIsCancelClicked(!isCancelClicked);
    }
    setSearchTermLength(searchInput.length);
    console.log(searchTermLength);
  };

  const onDateFilterChange = (option: HTMLOptionElement) => {
    setDateFilter(option.value);
  };

  const onAppealTypeFilterChange = (option: HTMLOptionElement) => {
    setAppealTypeFilter(option.value);
  };

  const onRespondentFilterChange = (option: HTMLOptionElement) => {
    setRespondentFilter(option.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setAppealTypeFilter('All');
    setRespondentFilter('All');
    setDateFilter('All');
    setCurrent(1);
    setIsCancelClicked(!isCancelClicked);
  }

  return (
    <>
      <PageHeaderSg headerText={'Forthcoming Hearings'} />

      <p>The hearings of the Local Taxation Chamber are held in public unless the Tribunal, on its own initiative or following an application by an appellant, decides that it is necessary that the hearing be held in private to ensure a fair hearing. 
        The details of upcoming hearings, including the date, time and location, are listed below. 
        If you wish to observe a video hearing, please contact us so that we can provide the joining instructions to you. 
        For more information, please see our <a href = { config.data ? 
          replaceDevLinkWithConfig(
            config!.data!.frontDoorUrl,
            config!.data!.storageAccount,
            AppDataLocalTax.observersGovernance.href,
          ) : '#' } target="_blank">
        {AppDataLocalTax.observersGovernance.name} <i className="bi bi-box-arrow-up-right"></i></a>.
      </p>
      <p>When searching for hearing information, you can use the drop down menus or type into the free text search box. Additional fields you can search for include: property address, assessor reference, property type, net annual value, rateable value, effective date, appeal date and appeal reason.</p>
      <p>Forthcoming hearings for the next few weeks are listed below:</p>

      <div className="d-flex flex-sm-column flex-md-row filter-row">
        <div className="filter">
          <SelectComponent 
            selectId={'date-filter'} 
            selectedValue={dateFilter} 
            selectedDisabled={false} 
            selectLabelText={'Date'} 
            selectName={'Date Filter'}
            selectOptions={dates} 
            onChange={onDateFilterChange} 
          />
        </div>
        <div className="filter">
          <SelectComponent 
            selectId={'appeal-type-filter'} 
            selectedValue={appealTypeFilter} 
            selectedDisabled={false} 
            selectLabelText={'Appeal Type'} 
            selectName={'Appeal Type Filter'}
            selectOptions={appealTypes}
            onChange={onAppealTypeFilterChange} 
          />
        </div>
        <div className="filter">
          <SelectComponent 
            selectId={'respondent-filter'} 
            selectedValue={respondentFilter} 
            selectedDisabled={false} 
            selectLabelText={'Respondent'} 
            selectName={'Respondent Filter'}
            selectOptions={respondents} 
            onChange={onRespondentFilterChange} 
          />
        </div>
      </div>
      <div className="d-flex flex-sm-column flex-md-row search-row">
        <div className="search">
          <SearchAsYouTypeComponent 
              inputId={"search"} 
              inputValue={searchTerm}
              labelText={"Search all fields (at least 3 characters are necessary)"} 
              inputType={"text"} 
              placeholder={"Search as you type..."} 
              searchLabel={"search"} 
              onSearch={(e: string) => {
                onSearchBoxChange(e);
              }} /> 
        </div>
        <div className='search-row-r-aligned-buttons flex-sm-column flex-md-row'>
          <div className='excel-export-button'>
            <ExcelExport data={filteredHearings} fileName={"hearings"} />
          </div>
          <div className="clear-button">
            <PrimaryButton
              buttonText={'Clear All'}
              onClick={clearSearch} 
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-sm-column flex-md-row search-row">
        <p className='nr-of-results'>Number of hearings: {filteredHearings.length}</p>
      </div>
      

      {!networkError && currentHearings && !noBlobs &&
      currentHearings.length === 0 && !noResults && (
        <Loader loadingText={'getting hearings'} padding={''} />
      )}       

      {!networkError && hearings && 
      currentHearings.length > 0 && !noResults && !isCancelClicked && (
        <div className="d-flex flex-column" >
          <PagingSg
            numberOfPages={Math.ceil(filteredHearings.length / itemsInAPage)}
            numberOfItems={filteredHearings.length}
            currentPage={current}
            numberOfPageLinks={5}
            pageClicked={childPageClicked}
          />
        <HearingsTable
          data={currentHearings}
          columns={HearingsParentColumns}
          getRowCanExpand={() => true}
          renderSubComponent={renderSubComponent}
        />
        </div>
      )}

{!networkError && hearings && 
      currentHearings.length > 0 && !noResults && isCancelClicked && (
        <div className="d-flex flex-column" >
          <PagingSg
            numberOfPages={Math.ceil(filteredHearings.length / itemsInAPage)}
            numberOfItems={filteredHearings.length}
            currentPage={current}
            numberOfPageLinks={5}
            pageClicked={childPageClicked}
          />
        <HearingsTable
          data={currentHearings}
          columns={HearingsParentColumns}
          getRowCanExpand={() => true}
          renderSubComponent={renderSubComponent}
        />
        </div>
      )}

{!networkError && noBlobs && (
        <PhaseBanner
          tag={'No Hearings'}
          link={'/contact-us'}
          text1={'It appears there are no hearings in the near future.'}
          text2={'if you believe this to be incorrect.'}
          linkText={'Contact us'}
        />
      )}

{!networkError && !noBlobs && currentHearings.length === 0 && noResults && (
        <PhaseBanner
          tag={'No Hearings Found'}
          link={'/hearings'}
          text1={
            'No hearings found based on the search term.'
          }
          text2={'Please change the search term, or select Reset filters link in order to remove all filters and the search term'}
          linkText={'Reset filters.'}
        />
      )}

      {networkError && (
        <ErrorSimple
          message={
            'We have been unable to retrieve hearing dates at the moment. Please try later or contact us via the contact page'
          }
          padding={'0.5rem'}
          margin={''}
          icon={'bi bi-exclamation-square-fill'}
          iconFontSize={'4rem'}
          textFontSize={''}
        />
      )}
    </>
  );
};

export default Hearings;
