import { AppDataLocalTax, AppDataProcessSteps } from '@scts/app-data';
import { PageHeaderSg, TimelineSteps } from '@scts-ui/component-library';
import { useConfigLoader } from 'dataaccessts';
import { LtcAppealType } from '@scts/models';
import {
  replaceDevLinkWithConfig,
  useTitleService,
} from '@scts/utilities';
import { useEffect, useState } from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import ProcessOpen from '../../components/process-open-html';
import ProcessClose from '../../components/process-close-html';

export const Apply = () => {
  const config = useConfigLoader();
  useTitleService('Make an Appeal: Local Taxation Chamber');
  const [ndrs, setNdrs] = useState<LtcAppealType[]>([]);
  const [cts, setCts] = useState<LtcAppealType[]>([]);
  const [water, setWater] = useState<LtcAppealType[]>([]);
  const [showNdrProcess, setShowNdrProcess] = useState(
    new Array(AppDataLocalTax.appealTypesNdr.items.length).fill(false)
  );
  const [showCtProcess, setShowCtProcess] = useState(
    new Array(AppDataLocalTax.appealTypesCt.items.length).fill(false)
  );
  const [showWaterProcess, setShowWaterProcess] = useState(
    new Array(AppDataLocalTax.appealTypesWater.items.length).fill(false)
  );

  useEffect(() => {
    if (config.data?.storageAccount) {
      AppDataLocalTax.appealTypesNdr.items.map((item) => {
        item.links.map((link) => {
          link.url = replaceDevLinkWithConfig(
            config.data.frontDoorUrl,
            config.data.storageAccount,
            link.url
          );
        });
      });

      AppDataLocalTax.appealTypesWater.items.map((item) => {
        item.links.map((link) => {
          link.url = replaceDevLinkWithConfig(
            config.data.frontDoorUrl,
            config.data.storageAccount,
            link.url
          );
        });
      });

      AppDataLocalTax.appealTypesCt.items.map((item) => {
        item.links.map((link) => {
          link.url = replaceDevLinkWithConfig(
            config.data.frontDoorUrl,
            config.data.storageAccount,
            link.url
          );
        });
      });
      setNdrs(AppDataLocalTax.appealTypesNdr.items);
      setCts(AppDataLocalTax.appealTypesCt.items);
      setWater(AppDataLocalTax.appealTypesWater.items);
    }
  }, [config.data]);

  const openNdrProcess = (i: number) => {
    const newArray = showNdrProcess.map((item, index) => {
      if (index === i) {
        item = !item;
      }
      return item;
    });

    setShowNdrProcess(newArray);
  };

  const openCtProcess = (i: number) => {
    const newArray = showCtProcess.map((item, index) => {
      if (index === i) {
        item = !item;
      }
      return item;
    });

    setShowCtProcess(newArray);
  };

  const openWaterProcess = (i: number) => {
    const newArray = showWaterProcess.map((item, index) => {
      if (index === i) {
        item = !item;
      }
      return item;
    });

    setShowWaterProcess(newArray);
  };

  return (
    <>
      <PageHeaderSg headerText={'Make An Appeal'} />
      <p className="pt-4"> New guidance has been published regarding witnesses providing evidence by telephone or video when located in a country outside the UK. For more information, please see our <a href = { config.data ? 
          replaceDevLinkWithConfig(
            config!.data!.frontDoorUrl,
            config!.data!.storageAccount,
            AppDataLocalTax.takingOfOralEvidence.href,
          ) : '#'} target="_blank">
        {AppDataLocalTax.takingOfOralEvidence.name} <i className="bi bi-box-arrow-up-right"></i></a>.</p> 
      <p className="pt-4">
        There are 10 different types of appeals across 3 areas.
      </p>
      <div className="apply-tabs">
        <Tabs defaultActiveKey="ndr" id="uncontrolled-tab-example">
          <Tab
            eventKey="ndr"
            title={'Non-Domestic Rate Appeals (' + ndrs.length + ') '}
          >
            <div className="card">
              <div className="card-body">
                <ul>
                  {ndrs.map((item, index) => (
                    <li key={index} className="pb-3 pt-3">
                      <h2>{item.title}</h2>
                      <pre>{item.body}</pre>
                      {/* fudge for UT */}
                      {item.id === 'ndr-appeal' && (
                        <div
                          className="upper-trib"
                          style={{ marginBottom: '1.5rem' }}
                        >
                          <a
                            rel="noreferrer"
                            target={'_blank'}
                            href="https://www.scotcourts.gov.uk/the-courts/tribunal-locations/the-upper-tribunal-for-scotland"
                          >
                            Upper tribunal for Scotland <i className="bi bi-box-arrow-up-right"></i>
                          </a>
                        </div>
                      )}
                      {/* end fudge for UT */}

                      <div className="d-flex justify-content-between">
                        {item.id !== 'ndr-upper' && (
                          <div aria-live="polite">
                            {!showNdrProcess[index] && (
                              <Button
                                data-testid={'ndr-element' + index}
                                variant="link"
                                className="d-flex"
                                onClick={() => openNdrProcess(index)}
                              >
                                <ProcessOpen />
                              </Button>
                            )}
                            {showNdrProcess[index] && (
                              <Button
                                variant="link"
                                className="d-flex"
                                onClick={() => openNdrProcess(index)}
                              >
                                <ProcessClose />
                              </Button>
                            )}
                          </div>
                        )}

                        <div className="apply-links d-flex flex-column">
                          {item.links &&
                            item.links.map((link, index) => (
                              <a
                                key={index + 10}
                                href={link.url}
                                target="_blank"
                                className="pt-2"
                                rel="noreferrer"
                              >
                                <span className="visually-hidden">
                                  {item.title + ' - '}{' '}
                                </span>
                                {link.text}{' '}
                                <i className="bi bi-box-arrow-up-right"></i>
                              </a>
                            ))}
                        </div>
                      </div>
                      <div aria-live="polite">
                        {showNdrProcess[index] && item.id === 'ndr-appeal' && (
                          <TimelineSteps
                            steps={AppDataProcessSteps.ndrBanding.steps}
                          />
                        )}

                        {showNdrProcess[index] &&
                          item.id === 'ndr-complaint' && (
                            <TimelineSteps
                              steps={AppDataProcessSteps.ndrComplaint.steps}
                            />
                          )}

                        {showNdrProcess[index] && item.id === 'ndr-penalty' && (
                          <TimelineSteps
                            steps={AppDataProcessSteps.ndrPenalty.steps}
                          />
                        )}
                      </div>
                      {ndrs.length !== index + 1 ? <hr /> : ''}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="ct"
            title={'Council Tax Appeals  (' + cts.length + ') '}
          >
            <div className="card">
              <div className="card body">
                <ul>
                  {cts.map((item, index) => (
                    <li key={index} className="pb-3 pt-3">
                      <h2>{item.title}</h2>
                      <pre>{item.body}</pre>
                      <div className="d-flex justify-content-between">
                        <div aria-live="polite">
                          {!showCtProcess[index] && (
                            <Button
                              data-testid={'ct-element' + index}
                              variant="link"
                              className="d-flex"
                              onClick={() => openCtProcess(index)}
                            >
                              <ProcessOpen />
                            </Button>
                          )}

                          {showCtProcess[index] && (
                            <Button
                              variant="link"
                              className="d-flex"
                              onClick={() => openCtProcess(index)}
                            >
                              <ProcessClose />
                            </Button>
                          )}
                        </div>
                        <div className="apply-links d-flex flex-column">
                          {item.links &&
                            item.links.map((link, index) => (
                              <a
                                key={index + 10}
                                href={link.url}
                                target="_blank"
                                className="pt-2"
                                rel="noreferrer"
                              >
                                <span className="visually-hidden">
                                  {item.title + ' - '}{' '}
                                </span>
                                {link.text}{' '}
                                <i className="bi bi-box-arrow-up-right"></i>
                              </a>
                            ))}
                        </div>
                      </div>
                      <div aria-live="polite">
                        <>
                          {/* last minute changes mean need to do a big switch statement.. could make this better but not sure its worth the effort */}
                          {showCtProcess[index] && item.id === 'ct-banding' && (
                            <TimelineSteps
                              steps={AppDataProcessSteps.ctBanding.steps}
                            />
                          )}
                          {showCtProcess[index] &&
                            item.id === 'ct-liability' && (
                              <TimelineSteps
                                steps={AppDataProcessSteps.ctLiability.steps}
                              />
                            )}
                          {showCtProcess[index] &&
                            item.id === 'ct-completion' && (
                              <TimelineSteps
                                steps={AppDataProcessSteps.ctCompletion.steps}
                              />
                            )}

                          {showCtProcess[index] && item.id === 'ct-invalid' && (
                            <TimelineSteps
                              steps={AppDataProcessSteps.ctBandingInValid.steps}
                            />
                          )}

                          {showCtProcess[index] &&
                            item.id === 'ct-reduction' && (
                              <TimelineSteps
                                steps={AppDataProcessSteps.ctReduction.steps}
                              />
                            )}

                          {showCtProcess[index] && item.id === 'ct-water' && (
                            <TimelineSteps
                              steps={AppDataProcessSteps.ctWater.steps}
                            />
                          )}

                          {showCtProcess[index] && item.id === 'ct-penalty' && (
                            <TimelineSteps
                              steps={AppDataProcessSteps.ctPenalty.steps}
                            />
                          )}
                        </>
                      </div>

                      {cts.length !== index + 1 ? <hr /> : ''}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="water"
            title={'Water Charges Appeals  (' + water.length + ') '}
          >
            <div className="card">
              <div className="card body">
                <ul>
                  {water.map((item, index) => (
                    <li key={index} className="pb-3 pt-3">
                      <h2>{item.title}</h2>
                      <pre>{item.body}</pre>
                      <div className="d-flex justify-content-between">
                        <div aria-live="polite">
                          {!showWaterProcess[index] && (
                            <Button
                              variant="link"
                              className="d-flex"
                              onClick={() => openWaterProcess(index)}
                            >
                              <ProcessOpen />
                            </Button>
                          )}

                          {showWaterProcess[index] && (
                            <Button
                              variant="link"
                              onClick={() => openWaterProcess(index)}
                              className="d-flex"
                            >
                              <ProcessClose />
                            </Button>
                          )}
                        </div>
                        <div className="apply-links d-flex flex-column">
                          {item.links &&
                            item.links.map((link, index) => (
                              <a
                                key={index + 10}
                                href={link.url}
                                target="_blank"
                                className="pt-2"
                                rel="noreferrer"
                              >
                                <span className="visually-hidden">
                                  {item.title + ' - '}{' '}
                                </span>
                                {link.text}{' '}
                                <i className="bi bi-box-arrow-up-right"></i>
                              </a>
                            ))}
                        </div>
                      </div>
                      <div aria-live="polite">
                        <>
                          {showWaterProcess[index] &&
                            item.id === 'ct-water' && (
                              <TimelineSteps
                                steps={AppDataProcessSteps.ctWater.steps}
                              />
                            )}
                        </>
                      </div>
                      {water.length !== index + 1 ? <hr /> : ''}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Apply;
